<script>
import { mapState } from "vuex";
const fishingGame = require("@/dailyGames/Fishing/game");
const cookingGame = require("@/dailyGames/Cooking/game");
const foragingGame = require("@/dailyGames/Foraging/game");
const miningGame = require("@/dailyGames/Mining/game");
const onlyQuestions = require("@/dailyGames/Questions/game");
export default {
  components: {
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    ErrorDialog: () =>
      import(
        /* webpackChunkName: "component-error-dialog" */ "@/components/ErrorDialog.vue"
      ),
  },
  computed: mapState({
    event: (state) => state.event.data,
    skin: (state) => state.skin.data,
    hatData: (state) => state.apparel.data,
    auth: (state) => state.auth.data,
    math: (state) => state.math.data,
    settings: (state) => state.settings.data,
  }),
  props: [
    //
  ],
  data: () => ({
    startTime: null,
    showDebug: false,
    difficulty: 1,
    endTime: null,
    env: process.env.VUE_APP_ENV,
    noOfRounds: 5,
    containerId: "game-container",
    gameInstance: null,
    bossRound: -1,
    master: 0,
    music: 0,
    sfx: 0,
    error: false,
    errorMessage: null,
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    questions: [],
    avatarItem: {
      shadow: null,
      back: null,
      right: null,
      body: null,
      outline: null,
      cheek: null,
      mouth: null,
      eye: null,
      hair: null,
      left: null,
      hat: null,
      color: null,
    },
    friendName: null,
    friendAvatarItem: {
      shadow: null,
      back: null,
      right: null,
      body: null,
      outline: null,
      cheek: null,
      mouth: null,
      eye: null,
      hair: null,
      left: null,
      hat: null,
      color: null,
    },
    avatarData: null,
    avatarBack: null,
    avatarRight: null,
    avatarLeft: null,
    avatarOutline: null,
    avatarMouth: null,
    avatarBody: null,
    avatarShadow: null,
    avatarEye: null,
    avatarHair: null,
    avatarCheek: null,
    avatarColor: null,
    avatarHat: null,
    mouth: [],
    body: [],
    outline: [],
    shadow: [],
    eye: [],
    hair: [],
    cheek: [],
    hat: [],
    left: [],
    right: [],
    back: [],
  }),
  async created() {
    this.difficulty = this.$route.query.difficulty;
    if (this.settings.debug) this.showDebug = true;
    else await this.checkEnergy();
  },
  mounted() {
    //
  },
  beforeDestroy() {
    // this.endTime = new Date();
    // const timeTakenInSeconds = this.endTime - this.startTime;
    // let event_data = this.$_.cloneDeep(this.event);
    // event_data.submission.time = timeTakenInSeconds;
    // this.$store.commit("updateEvent", event_data);

    let master = this.settings.audio.master;
    let music = this.settings.audio.music;
    this.$bgMusic.src = require("@/assets/sounds/music_bg_1.mp3");
    this.$bgMusic.volume = music * master;
    this.$bgMusic.play();
  },
  destroyed() {
    if (this.gameInstance) {
      this.gameInstance.destroy(true);
      this.gameInstance = null;
    }
  },
  methods: {
    skip(countCorrect) {
      let tempStore = this.$_.cloneDeep(this.math);
      tempStore["topic"] = this.$route.params.topic;
      tempStore["game"] = this.$route.params.game;
      tempStore["isSaved"] = false;
      let star;
      if (countCorrect == 3) {
        star = 1;
      } else if (countCorrect == 4) {
        star = 2;
      } else if (countCorrect == 5) {
        star = 3;
      } else {
        star = 0;
      }
      tempStore["star"] = star;
      tempStore["countCorrect"] = countCorrect;
      tempStore["score"] = countCorrect * 100;
      tempStore["difficulty"] = 1;
      tempStore["noOfRounds"] = this.noOfRounds;
      this.$store.commit("updateMath", tempStore);
      this.$router.push({
        name: "PageMathGameScore",
        params: {
          topic: this.$route.params.topic,
          game: this.$route.params.game,
        },
      });
    },
    async fetchQuestions() {
      this.api.url = this.$api.servers.question + "/questions";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.questions = resp;
        this.api.isLoading = false;
      };
      this.api.params = {
        lang: this.$_getLocale(),
        count: this.noOfRounds,
        key: this.$route.params.topic,
        showOption: false,
      };
      await this.$api.fetch(this.api);
    },
    async fetchFriends() {
      this.api.url =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/classmate";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        let randomFriend = Math.floor(Math.random() * resp.length);
        if (resp.length > 0 && resp[randomFriend].avatar) {
          this.friendName = resp[randomFriend].name;
          this.selectSkin(JSON.parse(resp[randomFriend].avatar), true);
        }
      };
      if (this.auth.Classroom) {
        this.api.params = {
          classroomId: this.auth.Classroom.id,
        };
        await this.$api.fetch(this.api);
      }
    },
    async checkEnergy() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/energy/check";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = async (resp) => {
        this.api.isLoading = false;
        if (resp.Energy.quantity > 0) {
          if (this.$route.params.game == "NOGAME") {
            await this.fetchQuestions();
            this.startGame();
          } else {
            await this.fetchQuestions();
            await this.fetchFriends();
            // this.startTime = new Date();
            // this.bossRound = Math.floor(Math.random() * (this.noOfRounds - 2)) + 2;
            this.avatarData = JSON.parse(this.auth.Player.avatar);
            this.selectSkin(this.avatarData);
            this.startGame();
          }
        } else {
          this.errorMessage = this.$t("string.noEnergy");
          this.error = true;
        }
      };
      this.api.params = { quantity: 1 };
      await this.$api.fetch(this.api);
    },
    clickStartGame() {
      this.showDebug = false;
      this.checkEnergy();
    },
    startGame() {
      let tempStore = this.$_.cloneDeep(this.math);
      tempStore["topic"] = this.$route.params.topic;
      tempStore["game"] = this.$route.params.game;
      tempStore["isSaved"] = false;
      tempStore["isDemo"] = false;
      this.$store.commit("updateMath", tempStore);

      let game = onlyQuestions;
      if (this.$route.params.game == "FISH") game = fishingGame;
      if (this.$route.params.game == "GEMS") game = miningGame;
      if (this.$route.params.game == "FRUIT") game = foragingGame;
      if (this.$route.params.game == "COOK") game = cookingGame;
      if (this.$route.params.game == "RANDOM") {
        const array = [fishingGame, miningGame, foragingGame, cookingGame];
        game = array[Math.floor(Math.random() * array.length)];
      }

      this.gameInstance = game.launch(this.containerId);
      this.gameInstance.scene.start("LoadingScene", {
        avatar: this.avatarItem,
        name: this.auth.User.name,
        questions: this.questions,
        bossRound: this.bossRound,
        friend: {
          name: this.friendName,
          avatar: this.friendAvatarItem,
        },
        env: this.env,
        noOfRounds: this.noOfRounds,
        difficulty: this.difficulty,
      });
      this.$bgMusic.pause();
      this.$bgMusic.src = null;
    },
    selectSkin(avatarData, isFriend = false) {
      this.body = this.skin.body;
      this.outline = this.skin.outline;
      this.mouth = this.skin.mouth;
      this.shadow = this.skin.shadow;
      this.eye = this.skin.eye;
      this.hair = this.skin.hair;
      this.cheek = this.skin.cheek;
      var outline = avatarData.body;
      var mouth = avatarData.mouth;
      var hat = avatarData.hat;
      var left = avatarData.left;
      var right = avatarData.right;
      var back = avatarData.back;
      var avatarOutline = this.outline.filter(function (entry) {
        return entry.character == outline;
      });
      this.avatarOutline = JSON.parse(avatarOutline[0].images).F0.url;
      var avatarBody = this.body.filter(function (entry) {
        return entry.character == outline;
      });
      this.avatarBody = JSON.parse(avatarBody[0].images).F0.url;
      var avatarMouth = this.mouth.filter(function (entry) {
        return entry.character == mouth;
      });
      this.avatarMouth = JSON.parse(avatarMouth[0].images).F0.url;

      if (hat) {
        var avatarHat = this.hatData.filter(function (entry) {
          return entry.key == hat;
        });
        this.avatarHat = JSON.parse(avatarHat[0].images).apparel.url;
      }
      if (left) {
        var avatarLeft = this.hatData.filter(function (entry) {
          return entry.key == left;
        });
        this.avatarLeft = JSON.parse(avatarLeft[0].images).apparel.url;
      }
      if (right) {
        var avatarRight = this.hatData.filter(function (entry) {
          return entry.key == right;
        });
        this.avatarRight = JSON.parse(avatarRight[0].images).apparel.url;
      }
      if (back) {
        var avatarBack = this.hatData.filter(function (entry) {
          return entry.key == back;
        });
        this.avatarBack = JSON.parse(avatarBack[0].images).apparel.url;
      }

      this.avatarShadow = JSON.parse(this.shadow[0].images).F0.url;
      this.avatarEye = JSON.parse(this.eye[0].images).F0.url;
      this.avatarHair = JSON.parse(this.hair[0].images).F0.url;
      this.avatarCheek = JSON.parse(this.cheek[0].images).F0.url;
      this.avatarColor = avatarData.pColor;
      if (isFriend) {
        this.friendAvatarItem.back = this.avatarBack;
        this.friendAvatarItem.body = this.avatarBody;
        this.friendAvatarItem.eye = this.avatarEye;
        this.friendAvatarItem.hat = this.avatarHat;
        this.friendAvatarItem.left = this.avatarLeft;
        this.friendAvatarItem.mouth = this.avatarMouth;
        this.friendAvatarItem.right = this.avatarRight;
        this.friendAvatarItem.shadow = this.avatarShadow;
        this.friendAvatarItem.outline = this.avatarOutline;
        this.friendAvatarItem.cheek = this.avatarCheek;
        this.friendAvatarItem.hair = this.avatarHair;
        this.friendAvatarItem.color = this.avatarColor;
      } else {
        this.avatarItem.back = this.avatarBack;
        this.avatarItem.body = this.avatarBody;
        this.avatarItem.eye = this.avatarEye;
        this.avatarItem.hat = this.avatarHat;
        this.avatarItem.left = this.avatarLeft;
        this.avatarItem.mouth = this.avatarMouth;
        this.avatarItem.right = this.avatarRight;
        this.avatarItem.shadow = this.avatarShadow;
        this.avatarItem.outline = this.avatarOutline;
        this.avatarItem.cheek = this.avatarCheek;
        this.avatarItem.hair = this.avatarHair;
        this.avatarItem.color = this.avatarColor;
      }
    },
    extiError() {
      this.$router.push({
        name: "PageMathMastery",
      });
    },
  },
};
</script>

<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="game-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="api.isError" :api="api" />
          <ErrorDialog
            v-if="error"
            :error="error"
            :message="errorMessage"
            @exitError="extiError"
          />
          <!-- EOC -->
          <div
            class="d-flex flex-column justify-center align-center dif"
            v-if="showDebug"
          >
            <v-btn
              class="ma-2"
              @click="clickStartGame()"
              width="260"
              :loading="api.isLoading"
              >Start game</v-btn
            >

            <div v-if="settings.debug" >
              <v-divider class="mt-6 mb-2"></v-divider>
              <v-btn
                color="secondary"
                class="mt-4"
                dark
                block
                depressed
                @click="skip(5)"
              >
                Skip & Complete [✓5]
              </v-btn>
              <v-btn
                color="secondary"
                class="mt-4"
                dark
                block
                depressed
                @click="skip(3)"
              >
                Skip & Complete [✓3]
              </v-btn>
              <v-btn
                color="secondary"
                class="mt-4"
                dark
                block
                depressed
                @click="skip(0)"
              >
                Skip & Complete [✓0]
              </v-btn>
            </div>
          </div>

          <div
            :id="containerId"
            style="max-width: 480px; padding: 0; max-height: 100vh !important"
          />
          <div
            v-if="!gameInstance && !showDebug"
            style="position: absolute"
            class="d-flex align-center justify-center flex-column"
          >
            <v-progress-linear
              color="brown darken-1"
              indeterminate
              height="10"
              style="max-width: 200px"
              class="my-5"
            ></v-progress-linear>
            <span class="brown--text py-2">
              {{ $t("string.game_loading_msg") }}</span
            >

            <v-btn
              depressed
              color="brown lighten-3"
              class="brown--text text--darken-3"
              @click="
                () => {
                  $router.go($router.currentRoute);
                }
              "
            >
              ↻ {{ $t("action.retry") }}
            </v-btn>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>

<style>
.game-body {
  max-width: 480px;
  height: calc(100% - 10px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
  position: relative;
}

.dif {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>